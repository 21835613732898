import React, { Component } from "react";
import { Link } from "gatsby";
import logo from "../assets/images/kwec-logo-dark.png";
class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      sticky: false,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    //Mobile Menu
    this.mobileMenu();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 70) {
      this.setState({
        sticky: true,
      });
    } else if (window.scrollY < 70) {
      this.setState({
        sticky: false,
      });
    }
  };

  mobileMenu = () => {
    //Mobile Menu Toggle
    let mainNavToggler = document.querySelector(".menu-toggler");
    let mainNav = document.querySelector(".main-navigation");

    mainNavToggler.addEventListener("click", function () {
      mainNav.style.display =
        mainNav.style.display !== "block" ? "block" : "none";
    });
  };

  render() {
    return (
      <header className="site-header site-header__home-three ">
        <nav
          className={`navbar navbar-expand-lg navbar-light header-navigation stricky ${
            this.state.sticky ? "stricked-menu stricky-fixed" : ""
          }`}
        >
          <div className="container clearfix">
            <div className="logo-box clearfix">
              <Link to="/" className="navbar-brand">
                <img
                  src={logo}
                  className="main-logo"
                  width="250"
                  alt="Awesome alter text"
                />
              </Link>
              <button className="menu-toggler">
                <span className="kipso-icon-menu"></span>
              </button>
            </div>
            <div className="main-navigation">
              <ul className=" navigation-box">
                <li>
                  <Link
                    to="/"
                    activeStyle={{
                      color: "#f16101",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    activeStyle={{
                      color: "#f16101",
                    }}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/awards"
                    activeStyle={{
                      color: "#f16101",
                    }}
                  >
                    Awards
                  </Link>
                </li>
                <li>
                  <Link
                    to="/courses"
                    activeStyle={{
                      color: "#f16101",
                    }}
                  >
                    Courses
                  </Link>
                </li>
                <li>
                  <Link
                    to="/projects"
                    activeStyle={{
                      color: "#f16101",
                    }}
                  >
                    Projects
                  </Link>
                </li>
                <li>
                  <Link
                    to="/gallery"
                    activeStyle={{
                      color: "#f16101",
                    }}
                  >
                    Gallery
                  </Link>
                </li>
                <li>
                  <Link
                    to="/edugames"
                    activeStyle={{
                      color: "#f16101",
                    }}
                  >
                    Edugames
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    activeStyle={{
                      color: "#f16101",
                    }}
                  >
                    Contact
                  </Link>
                </li>
                <li>
                  <Link
                    to="/faq"
                    activeStyle={{
                      color: "#f16101",
                    }}
                  >
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Navbar;
