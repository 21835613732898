import React from "react";
import footer1 from "../assets/images/footer-1-1.png";
import footer2 from "../assets/images/footer-1-2.png";
import footer3 from "../assets/images/footer-1-3.png";
import footer4 from "../assets/images/footer-1-4.png";
import footer5 from "../assets/images/footer-1-5.png";
import footer6 from "../assets/images/footer-1-6.png";

const Footer = () => {
  function scrollTop() {
    window.scrollTo(0, 0);
  }
  return (
    <div>
      <footer className="site-footer">
        <div className="site-footer__upper">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-sm-12">
                <div className="footer-widget footer-widget__about">
                  <h2 className="footer-widget__title">
                    Kids World Education Consultants
                  </h2>
                  <p className="footer-widget__text">
                    Kids World Education Consultants has been offering
                    innovative, age-appropriate and theme-based products as well
                    as services pertaining to early childhood education in India
                    and abroad, since 2012.
                  </p>
                  <div className="footer-widget__btn-block">
                    <a href="#none" className="thm-btn">
                      Contact
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-sm-12">
                <div className="footer-widget footer-widget__contact">
                  <ul className="list-unstyled footer-widget__course-list">
                    <li>
                      <h2 className="site-footer__location">Mumbai</h2>
                      <p>Ms Neeti Nagarkar</p>
                      <a href="tel:+91 98201 50447" className="my-2">
                        +91 98201 50447
                      </a>
                    </li>
                    <li>
                      <h2>Pune</h2>
                      <p>Ms Anagha Bahulikar</p>

                      <a href="tel:+91 98201 50447" className="my-2">
                        +91 70588 21773
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <div className="site-footer__bottom">
          <div className="container">
            <p className="site-footer__copy">
              &copy; Copyright 2020 by <br />
              <a href="#none">Kids World Education Consultants 2020</a>
            </p>
            {/* <p className="site-footer__copy">
              Developed by <a href="#none">Rohan Kokkula</a>
            </p> */}
            <div className="site-footer__social">
              <div
                onClick={scrollTop}
                onKeyDown={scrollTop}
                role="button"
                tabIndex={0}
                className="scroll-to-target site-footer__scroll-top"
              >
                <i className="kipso-icon-top-arrow"></i>
              </div>

              <a href="#none">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#none">
                <i className="fab fa-facebook-square"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Footer;
